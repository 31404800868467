import React from "react";
import { graphql } from "gatsby";

import { HomeHeader } from "../components/Headers/HomeHeader";
import { SEO, Layout, Footer } from "../components";
import { ContactUsForm } from "../components/common/ContactUsForm";

type PageProps = {
  data: {
    contentfulPage: {
      title: string,
      seoDescription: string,
      socialShareThumbnail: {
        file: {
          url: string
        }
      }
    }
  }
}

const ContactUs = ({ data }: PageProps) => {
  const { title, seoDescription, socialShareThumbnail } = data.contentfulPage;
  return (
    <Layout
      header={<HomeHeader style={{ marginBottom: 0 }} />}
      footer={<Footer />}
    >
      <SEO
        title={title}
        desc={seoDescription}
        image={socialShareThumbnail.file.url}
      />
      <section>
        <ContactUsForm />
      </section>
    </Layout>
  );
};
export default ContactUs;

export const query = graphql`
  query ContactPageQuery {
    contentfulPage(slug: {eq: "contact-us"}) {
      title
      seoDescription
      socialShareThumbnail {
        file {
          url
        }
      }
    }
  }
`